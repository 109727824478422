<template>
  <div class="container mx-auto home">
    <router-link to="/swot/" class="px-3 py-2 text-white bg-blue-400 rounded text-md"
      ><span class="text-md">&#10094;&#10094;</span>&nbsp;&nbsp; All Active Clients</router-link
    ><br /><br />
    <Clients :archived="archived" />
    <br /><br />
  </div>
</template>

<script>
  import Clients from "@/components/swot/SwotClients.vue";
  export default {
    components: {
      Clients,
    },

    data() {
      return {
        clients: [],
        loading: true,
        archived: true,
      };
    },
  };
</script>

<style scoped></style>
